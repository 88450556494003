import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { apiSlice } from '../api/base_slice';

import { rootReducer } from './root_reducer';

const isDevelopmentEnv = process.env.NODE_ENV === 'development'

// When unit-testing there are times when a component uses Redux state populated from
// sibling or parent actions.
// This is to allow our specs to optionally pass in a preloaded state and avoid the overhead of
// manually dispatching actions w/in the spec or mocking the store itself.
export const buildStore = (preloadedState = {}) => configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    // disable for test env to speed up specs (always disabled for prod)
    // https://redux-toolkit.js.org/api/getDefaultMiddleware#api-reference
    actionCreatorCheck: isDevelopmentEnv,
    immutableCheck: isDevelopmentEnv,
    serializableCheck: isDevelopmentEnv,
  }).concat(apiSlice.middleware),
  preloadedState,
});

const store = buildStore();

setupListeners(store.dispatch);

export default store;
