/* eslint-disable no-param-reassign */
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';

import {
  participantPopulationOptInFormsApi,
} from 'api/participant_population_opt_in_forms';

import {
  populationInviteFiltersApi,
} from 'api/population_invite_filters';

import {
  populationLabelsApi,
} from 'api/population_labels';

import {
  populationAttributesApi,
} from 'api/population_attributes';

import { segmentsApi } from 'api/segments';

import {
  teamProjectsApi,
} from 'api/team_projects';

import {
  PROJECT_INVITE_LIMIT_MAX,
  PROJECT_INVITE_LIMIT_MIN,
} from 'researcher/participant_search/invite_rules_drawer/constants';

import {
  buildFilterList,
  getDefaultParams,
  getParamKey,
} from 'researcher/participant_search/segment_builder/lib/filter_list';

import {
  FILTER_RULE_APPLIED_SECTIONS,
  FILTER_RULE_PENDING_SECTIONS,
  appliedSectionLookup,
  buildFromServerFilterRules,
  hasApplicableFilterRule,
} from 'researcher/participant_search/segment_builder/lib/filter_utils';

import { sortAlphabeticalByName } from 'api/utils';

const adapter = createEntityAdapter();

const matchPopulationInviteFilterFetched = isAnyOf(
  populationInviteFiltersApi.endpoints.getInviteFilter.matchFulfilled,
);

const matchLabelsFetched = isAnyOf(
  populationLabelsApi.endpoints.getPopulationLabels.matchFulfilled,
);

const matchOptInFormsFetched = isAnyOf(
  participantPopulationOptInFormsApi.endpoints.getParticipantPopulationOptInForms.matchFulfilled,
);

const matchPopulationAttributesFetched = isAnyOf(
  populationAttributesApi.endpoints.getPopulationAttributes.matchFulfilled,
);

const matchSegmentsFetched = isAnyOf(segmentsApi.endpoints.getSegments.matchFulfilled);

const matchTeamProjectsFetched = isAnyOf(
  teamProjectsApi.endpoints.getTeamProjects.matchFulfilled,
);

const appliedInitialState = {
  filterFlags: {},
  filterKeys: [],
  filterRules: {},
};

const pendingInitialState = {
  ...appliedInitialState,
  removedFilterKeys: [],
};

export const initialState = {
  filterRules: {
    filters: {},
    applied: {
      [FILTER_RULE_APPLIED_SECTIONS.edit]: appliedInitialState,
      [FILTER_RULE_APPLIED_SECTIONS.inviteFilter]: appliedInitialState,
      [FILTER_RULE_APPLIED_SECTIONS.segment]: appliedInitialState,
    },
    pending: {
      [FILTER_RULE_PENDING_SECTIONS.builder]: pendingInitialState,
      [FILTER_RULE_PENDING_SECTIONS.create]: pendingInitialState,
      [FILTER_RULE_PENDING_SECTIONS.edit]: pendingInitialState,
      [FILTER_RULE_PENDING_SECTIONS.inviteFilter]: pendingInitialState,
    },
  },
  inviteFilter: {
    applied: {},
    pending: {},
  },
  labels: adapter.getInitialState(),
  optInForms: adapter.getInitialState(),
  populationAttributes: adapter.getInitialState(),
  projects: adapter.getInitialState(),
  segments: {
    activeId: null,
    meta: {},
    ...adapter.getInitialState(),
  },
};

export const segmentsSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    ADD_FILTER_RULE_PARAM: (state, action) => {
      const { filterKey, filterRuleSection } = action.payload;

      const filter = state.filterRules.filters[filterKey];
      const filterRule = state.filterRules.pending[filterRuleSection].filterRules[filterKey];

      const newParams = [...filterRule.params];

      newParams.push(getDefaultParams(filter.isNotSetOperand));

      const newFilter = {
        ...filterRule,
        params: newParams,
      };

      state.filterRules.pending[filterRuleSection].filterRules[filterKey] = newFilter;
    },
    CLEAR_FILTERS: (state) => {
      state.segments.activeId = initialState.segments.activeId;
      state.filterRules.applied = initialState.filterRules.applied;
      state.filterRules.pending = initialState.filterRules.pending;
    },
    CREATE_FILTER_RULE: (state, action) => {
      const { filterKey, filterRuleSection } = action.payload;

      const filter = state.filterRules.filters[filterKey];

      state.filterRules.pending[filterRuleSection].filterRules[filterKey] = { ...filter.defaultFilterRuleShape };
    },
    REMOVE_FILTER_RULE: (state, action) => {
      const { filterKey, filterRuleSection } = action.payload;

      const { removedFilterKeys } = state.filterRules.pending[filterRuleSection];

      if (!removedFilterKeys.includes(filterKey)) removedFilterKeys.push(filterKey);

      state.filterRules.pending[filterRuleSection].removedFilterKeys = removedFilterKeys;

      delete state.filterRules.pending[filterRuleSection].filterFlags[filterKey];
      delete state.filterRules.pending[filterRuleSection].filterRules[filterKey];

      state.filterRules.pending[filterRuleSection].filterKeys =
        state.filterRules.pending[filterRuleSection].filterKeys.filter(key => key !== filterKey);
    },
    REMOVE_FILTER_RULE_PARAM: (state, action) => {
      const { filterKey, filterParamKey, filterRuleSection } = action.payload;

      const filter = state.filterRules.filters[filterKey];
      const filterRule = state.filterRules.pending[filterRuleSection].filterRules[filterKey]

      let newParams = [...filterRule.params];

      if (newParams.length === 1) {
        newParams = [getDefaultParams(filter.isNotSetOperand)];
      } else {
        const paramIndex = newParams.findIndex(param => param.key === filterParamKey);
        newParams.splice(paramIndex, 1);
      }

      const newFilterRule = {
        ...filterRule,
        params: newParams,
      }

      state.filterRules.pending[filterRuleSection].filterRules[filterKey] = newFilterRule;
    },
    RESET_PENDING_FILTERS: (state, action) => {
      const { filterRuleSection } = action.payload;

      state.filterRules.pending[filterRuleSection] = pendingInitialState;
    },
    SELECT_FILTER_RULE: (state, action) => {
      const { activeFilterKey, filterKey, filterRuleSection } = action.payload;

      if (activeFilterKey) {
        const activeFilterIndex =
          state.filterRules.pending[filterRuleSection].filterKeys.indexOf(activeFilterKey);

        if (activeFilterIndex !== -1) {
          state.filterRules.pending[filterRuleSection].filterKeys[activeFilterIndex] = filterKey;
        }

        delete state.filterRules.pending[filterRuleSection].filterRules[activeFilterKey];
      } else {
        state.filterRules.pending[filterRuleSection].filterKeys =
          [...state.filterRules.pending[filterRuleSection].filterKeys, filterKey];
      }
    },
    SELECT_FILTER_RULE_OPERAND: (state, action) => {
      const { filterKey, filterRuleSection, operand } = action.payload;

      const filterRule = state.filterRules.pending[filterRuleSection].filterRules[filterKey];

      const newFilterRule = {
        ...filterRule,
        operand: operand.key,
        params: [getDefaultParams(filterRule.isNotSetOperand)],
      };

      state.filterRules.pending[filterRuleSection].filterRules[filterKey] = newFilterRule;
    },
    SET_ACTIVE_SEGMENT_ID: (state, action) => {
      const { segmentId } = action.payload;

      state.segments.activeId = segmentId;
    },
    SET_APPLIED_FILTER_RULES: (state, action) => {
      const { filterRuleSection, filterRules } = action.payload;

      const applied = buildFromServerFilterRules({
        filters: state.filterRules.filters,
        filterRules,
      });

      const section = appliedSectionLookup[filterRuleSection];

      state.filterRules.applied[section] = {
        ...appliedInitialState,
        filterFlags: applied.filterFlags,
        filterKeys: applied.filterKeys,
        filterRules: applied.filterRules,
      };
    },
    SET_FILTER_RULE: (state, action) => {
      const { filterKey, pendingFilterRule, filterRuleSection } = action.payload;

      state.filterRules.pending[filterRuleSection].filterRules[filterKey] = pendingFilterRule;
    },
    SET_INVITE_FILTER_ATTRIBUTES: (state, action) => {
      const { attributes } = action.payload;

      state.inviteFilter.pending = {
        ...state.inviteFilter.pending,
        ...attributes,
      };
    },
    SET_PENDING_FILTER_RULES: (state, action) => {
      const { filterRules, filterRuleSection } = action.payload;

      const pending = buildFromServerFilterRules({
        filters: state.filterRules.filters,
        filterRules,
      });

      state.filterRules.pending[filterRuleSection] = {
        ...state.filterRules.pending[filterRuleSection],
        filterFlags: {
          ...state.filterRules.pending[filterRuleSection].filterFlags,
          ...pending.filterFlags,
        },
        filterKeys: {
          ...state.filterRules.pending[filterRuleSection].filterKeys,
          ...pending.filterKeys,
        },
        filterRules: {
          ...state.filterRules.pending[filterRuleSection].filterRules,
          ...pending.filterRules,
        },
      };
    },
    SET_PENDING_FROM_APPLIED: (state, action) => {
      const { filterRuleSection } = action.payload;

      const section = appliedSectionLookup[filterRuleSection];

      state.filterRules.pending[filterRuleSection] = {
        ...pendingInitialState,
        ...state.filterRules.applied[section],
      };
    },
    SET_PENDING_FROM_SEGMENT: (state, action) => {
      const { segmentId, filterRuleSection } = action.payload;

      const { filterRules } = state.segments.entities[segmentId];

      const pending = buildFromServerFilterRules({
        filters: state.filterRules.filters,
        filterRules,
      });

      const section = appliedSectionLookup[filterRuleSection];

      state.filterRules.applied[section] = {
        ...pendingInitialState,
        filterFlags: pending.filterFlags,
        filterKeys: pending.filterKeys,
        filterRules: pending.filterRules,
      };

      state.filterRules.pending[filterRuleSection] = {
        ...pendingInitialState,
        filterFlags: pending.filterFlags,
        filterKeys: pending.filterKeys,
        filterRules: pending.filterRules,
      };
    },
    TOGGLE_IS_NOT_SET_CHECKED: (state, action) => {
      const { filterKey, filterRuleSection } = action.payload;

      const isNotSetChecked = state.filterRules.pending[filterRuleSection].filterFlags[filterKey]?.isNotSetChecked;

      state.filterRules.pending[filterRuleSection].filterFlags[filterKey] = {
        isNotSetChecked: !isNotSetChecked,
        isSetChecked: false,
      };
    },
    TOGGLE_IS_SET_CHECKED: (state, action) => {
      const { filterKey, filterRuleSection } = action.payload;

      const isSetChecked = state.filterRules.pending[filterRuleSection].filterFlags[filterKey]?.isSetChecked;

      if (!isSetChecked) {
        state.filterRules.pending[filterRuleSection].filterRules[filterKey].params = [{ key: getParamKey() }];
      }

      state.filterRules.pending[filterRuleSection].filterFlags[filterKey] = {
        isNotSetChecked: false,
        isSetChecked: !isSetChecked,
      };
    },
    UPDATE_FILTER_RULE_PARAM: (state, action) => {
      const {
        filterKey,
        filterRuleKey,
        paramKey,
        paramValue,
        filterRuleSection,
      } = action.payload;

      const filterRule = state.filterRules.pending[filterRuleSection].filterRules[filterKey];

      const paramIndex = filterRule.params.findIndex(param => param.key === filterRuleKey);

      const newParam = {
        ...filterRule.params[paramIndex],
        [paramKey]: paramValue,
      };

      const { params } = filterRule;

      params.splice(paramIndex, 1, newParam);

      const newFilterRule = {
        ...filterRule,
        params,
      };

      state.filterRules.pending[filterRuleSection].filterRules[filterKey] = newFilterRule;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(matchPopulationInviteFilterFetched, (state, action) => {
      state.inviteFilter.applied = action.payload;
      state.inviteFilter.pending = action.payload;
    });
    builder.addMatcher(matchLabelsFetched, (state, action) => {
      adapter.setAll(state.labels, action.payload);
    });
    builder.addMatcher(matchOptInFormsFetched, (state, action) => {
      adapter.setAll(state.optInForms, action.payload);
    });
    builder.addMatcher(matchPopulationAttributesFetched, (state, action) => {
      const populationAttributes = action.payload;

      state.filterRules.filters = buildFilterList({ populationAttributes });

      adapter.setAll(state.populationAttributes, populationAttributes);
    });
    builder.addMatcher(matchSegmentsFetched, (state, action) => {
      state.segments.meta = action.payload.meta;
      adapter.setAll(state.segments, action.payload.data);
    });
    builder.addMatcher(matchTeamProjectsFetched, (state, action) => {
      adapter.setAll(state.projects, action.payload);
    });
  },
});

export const {
  selectAll: selectAllLabels,
  selectEntities: selectLabels,
} = adapter.getSelectors(state => state.segments.labels);

export const {
  selectEntities: selectOptInForms,
} = adapter.getSelectors(state => state.segments.optInForms);

export const {
  selectAll: selectAllPopulationAttributes,
  selectIds: selectPopulationAttributeIds,
} = adapter.getSelectors(state => state.segments.populationAttributes);

export const {
  selectEntities: selectSegmentsById,
  selectAll: selectAllSegments,
  selectEntities: selectSegments,
} = adapter.getSelectors(state => state.segments.segments);

export const selectSegmentsByAccessLevel = createSelector(
  [selectSegments],
  (segments) => {
    let privateSegments = [];
    let sharedSegments = [];

    Object.values(segments).forEach(segment => {
      if (segment.teamPermissionRoleId) {
        sharedSegments.push(segment);
      } else {
        privateSegments.push(segment);
      }
    });

    privateSegments = sortAlphabeticalByName(privateSegments);
    sharedSegments = sortAlphabeticalByName(sharedSegments);

    return { privateSegments, sharedSegments };
  },
);
export const selectActiveSegmentId = state => state.segments.segments.activeId;

const selectPendingSections = state => state.segments.filterRules.pending;

export const selectPendingSection = createSelector([
  selectPendingSections,
  (_, sectionKey) => sectionKey,
], (pendingSections, sectionKey) => pendingSections[sectionKey]);

const selectAppliedEditSection =
  state => state.segments.filterRules.applied[FILTER_RULE_APPLIED_SECTIONS.edit];
const selectAppliedInviteFilterSection =
  state => state.segments.filterRules.applied[FILTER_RULE_APPLIED_SECTIONS.inviteFilter];
export const selectAppliedSegmentSection =
  state => state.segments.filterRules.applied[FILTER_RULE_APPLIED_SECTIONS.segment];
export const selectAppliedSegmentFilterRules =
  state => state.segments.filterRules.applied[FILTER_RULE_APPLIED_SECTIONS.segment].filterRules;

export const selectPersistedFilterRule = createSelector([
  selectAppliedEditSection,
  selectAppliedInviteFilterSection,
  selectAppliedSegmentSection,
  (_, keys) => keys,
], (
  editAppliedFilterRulesSection,
  inviteFilterAppliedFilterRulesSection,
  segmentFilterAppliedRulesSection,
  keys,
) => {
  const { filterRuleSection, filterKey } = keys;

  const appliedSectionKey = appliedSectionLookup[filterRuleSection];

  switch (appliedSectionKey) {
    case FILTER_RULE_APPLIED_SECTIONS.edit:
      return editAppliedFilterRulesSection.filterRules[filterKey];
    case FILTER_RULE_APPLIED_SECTIONS.inviteFilter:
      return inviteFilterAppliedFilterRulesSection.filterRules[filterKey];
    case FILTER_RULE_APPLIED_SECTIONS.segment:
      return segmentFilterAppliedRulesSection.filterRules[filterKey];
    default:
      return undefined;
  }
});

export const selectSegmentRoles = state => state.segments.segments.meta?.roles;

export const selectFilters = state => state.segments.filterRules.filters;

export const selectInviteFilter = state => state.segments.inviteFilter.pending;
export const selectAppliedInviteFilter = state => state.segments.inviteFilter.applied;

const selectInviteFilterMultiplier = state => state.segments.inviteFilter.pending.projectInviteMultiplier;
const selectInviteFilterMultiplierEnabled = state => state.segments.inviteFilter.pending.projectInviteMultiplierEnabled;

export const selectAppliedInviteFilterRules =
  state => state.segments.filterRules.applied[FILTER_RULE_APPLIED_SECTIONS.inviteFilter];
export const selectInviteFilterRules =
  state => state.segments.filterRules.pending[FILTER_RULE_PENDING_SECTIONS.inviteFilter];

export const selectProjectInviteMultiplierOutOfRange = createSelector([
  selectInviteFilterMultiplier,
  selectInviteFilterMultiplierEnabled,
], (projectInviteMultiplier, projectInviteMultiplierEnabled) =>
  projectInviteMultiplierEnabled &&
  Number.isInteger(projectInviteMultiplier) && (
    projectInviteMultiplier < PROJECT_INVITE_LIMIT_MIN ||
    projectInviteMultiplier > PROJECT_INVITE_LIMIT_MAX
  ));

const selectPendingCreateFilterFlags = state =>
  state.segments.filterRules.pending[FILTER_RULE_PENDING_SECTIONS.create].filterFlags;

const selectPendingCreateFilterRules = state =>
  state.segments.filterRules.pending[FILTER_RULE_PENDING_SECTIONS.create].filterRules;

const selectPendingEditFilterFlags = state =>
  state.segments.filterRules.pending[FILTER_RULE_PENDING_SECTIONS.edit].filterFlags;

const selectPendingEditFilterRules = state =>
  state.segments.filterRules.pending[FILTER_RULE_PENDING_SECTIONS.edit].filterRules;

export const selectFilterOptions = createSelector([
  selectFilters,
], filters => Object.values(filters).map(filter => ({
  custom: !!filter.attribute,
  label: filter.name,
  value: filter.id,
})));

export const selectAllFiltersCount = createSelector([
  selectFilters,
], filters => Object.keys(filters).length);

export const selectActiveSegment = createSelector([
  selectActiveSegmentId,
  selectSegments,
], (activeSegmentId, segmentsByIds) => segmentsByIds[activeSegmentId]);

export const selectAppliedFilterCount = createSelector([
  selectAppliedSegmentFilterRules,
], filterRules => Object.keys(filterRules).length);

export const selectHasApplicableCreateFilterRule = createSelector([
  selectPendingCreateFilterFlags,
  selectPendingCreateFilterRules,
], (filterFlags, filterRules) =>
  hasApplicableFilterRule({ filterFlags, filterRules }));

export const selectHasApplicableEditFilterRule = createSelector([
  selectPendingEditFilterFlags,
  selectPendingEditFilterRules,
], (filterFlags, filterRules) =>
  hasApplicableFilterRule({ filterFlags, filterRules }));

export const selectSegmentName = createSelector([
  selectSegments,
  (_, segmentId) => segmentId,
], (segmentsById, segmentId) => segmentsById[segmentId]?.name);

export const {
  ADD_FILTER_RULE_PARAM,
  CLEAR_FILTERS,
  CREATE_FILTER_RULE,
  REMOVE_FILTER_RULE,
  REMOVE_FILTER_RULE_PARAM,
  RESET_PENDING_FILTERS,
  SELECT_FILTER_RULE,
  SELECT_FILTER_RULE_OPERAND,
  SET_ACTIVE_SEGMENT_ID,
  SET_APPLIED_FILTER_RULES,
  SET_FILTER_RULE,
  SET_INVITE_FILTER_ATTRIBUTES,
  SET_PENDING_FILTER_RULES,
  SET_PENDING_FROM_APPLIED,
  SET_PENDING_FROM_SEGMENT,
  TOGGLE_IS_NOT_SET_CHECKED,
  TOGGLE_IS_SET_CHECKED,
  UPDATE_FILTER_RULE_PARAM,
} = segmentsSlice.actions;

export const segmentsSliceReducer = segmentsSlice.reducer;
